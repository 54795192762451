define("discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/admin-user-details/ford-profile-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="details">
    <h1>{{i18n "admin.profile_sync_details.title"}}</h1>
    {{#each-in this.model.ford_profile as |key value|}}
      <div class="display-row">
        <div class="field">
          {{i18n (concat "ford.profile_field." key)}}
        </div>
  
        <div class="value">
          {{#if value}}
            {{value}}
          {{else}}
            &mdash;
          {{/if}}
        </div>
        <div class="controls"></div>
      </div>
    {{/each-in}}
  </section>
  */
  {
    "id": "Ya8FQqY8",
    "block": "[[[10,\"section\"],[14,0,\"details\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"admin.profile_sync_details.title\"],null]],[13],[1,\"\\n\"],[42,[28,[37,4],[[30,0,[\"model\",\"ford_profile\"]]],null],null,[[[1,\"    \"],[10,0],[14,0,\"display-row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[28,[37,6],[\"ford.profile_field.\",[30,2]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[1,[30,1]],[1,\"\\n\"]],[]],[[[1,\"          —\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1,2]],null],[13]],[\"value\",\"key\"],false,[\"section\",\"h1\",\"i18n\",\"each\",\"-each-in\",\"div\",\"concat\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/admin-user-details/ford-profile-info.hbs",
    "isStrictMode": false
  });
});