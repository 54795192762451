define("discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/user-card-before-badges/data-source", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{html-safe
    (i18n
      "ford.data_source_info"
      link=this.siteSettings.ford_profile_sync_data_source_url
    )
  }}
  */
  {
    "id": "Pgo05npB",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"ford.data_source_info\"],[[\"link\"],[[30,0,[\"siteSettings\",\"ford_profile_sync_data_source_url\"]]]]]],null]]],[],false,[\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/user-card-before-badges/data-source.hbs",
    "isStrictMode": false
  });
});