define("discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/groups-form-membership-below-automatic/ford-membership-groups", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n "admin.list_manager_groups"}}</label>
  
    <label for="ford_groups">
      {{i18n "admin.list_manager_groups_desc"}}
    </label>
  
    {{multi-select
      allowAny=false
      name="ford_groups"
      content=this.model.available_ford_groups
      value=this.model.ford_groups
      class="group-form-ford-groups"
      onChange=(action (mut this.model.ford_groups))
    }}
  </div>
  */
  {
    "id": "5Gt1n0CJ",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"admin.list_manager_groups\"],null]],[13],[1,\"\\n\\n  \"],[10,\"label\"],[14,\"for\",\"ford_groups\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.list_manager_groups_desc\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"allowAny\",\"name\",\"content\",\"value\",\"class\",\"onChange\"],[false,\"ford_groups\",[30,0,[\"model\",\"available_ford_groups\"]],[30,0,[\"model\",\"ford_groups\"]],\"group-form-ford-groups\",[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"model\",\"ford_groups\"]]],null]],null]]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"multi-select\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/groups-form-membership-below-automatic/ford-membership-groups.hbs",
    "isStrictMode": false
  });
});